import Img from 'gatsby-image'
import React from 'react'

import { useImages } from '../hooks/use-images'

export interface ImageProps {
  path: string
}

export const Image: React.FC<ImageProps> = ({ path }) => {
  const { getImage } = useImages()

  if (!path) {
    return null
  }

  if (path.endsWith('.svg')) {
    const parts = path.split('/')
    const url = `/assets/svgs/${parts[parts.length - 1]}`

    return <img src={url} />
  }

  const fluid = getImage(path)?.fluid

  return fluid ? <Img fluid={fluid} /> : null
}
