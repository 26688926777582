import '../../styles/components/icon.scss'

import React from 'react'

export interface IconButtonProps {
  href?: string
  variant?: 'primary' | 'secondary'
  onClick?: () => void
  className?: string
  disabled?: boolean
  [x: string]: any
}

export const Download: React.FC = () => (
  <svg
    className="icon--download"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11" stroke="white" />
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      x="7"
      y="7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 2.75L4.5 6.25L1 2.75" />
      <path d="M4.5 6V0" />
      <path d="M8 8L1 8" />
    </svg>
  </svg>
)
