import { useMemo } from 'react'

import { useCmsContext } from '../../contexts/cms'

export type CaseStudy = cms.CMSData['case-studies'][string]

export const useCaseStudies = (): {
  table: Map<string, CaseStudy>
  list: Array<CaseStudy>
} => {
  const { cmsData } = useCmsContext()

  return useMemo(() => {
    const entities = Object.entries(cmsData['case-studies']).map<
      [string, CaseStudy]
    >(([key, val]) => [val?.identifier ?? key, val as CaseStudy])

    return {
      table: new Map(entities),
      list: entities.map(([, entity]) => entity),
    }
  }, [cmsData])
}
