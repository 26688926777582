import { useEffect, useState } from 'react'

import type { Screen } from '../config/screens'
import {
  SCREENS,
  SCREENS_HIERARCHY,
  SCREENS_HIERARCHY_REVERSE,
} from '../config/screens'
import { win } from '../utils/monads'

type BreakpointMap = Partial<Record<Screen, string>>
type ScreenMap = Partial<Record<Screen, boolean>>

const queries = SCREENS_HIERARCHY.reduce<BreakpointMap>((acc, name) => {
  const breakpoint = SCREENS[name]

  return { ...acc, [name]: `(min-width: ${breakpoint}rem)` }
}, {})

type SubscribeFunc = (screens: ScreenMap) => void
const subscribers = new Map<number, SubscribeFunc>()
let subUid = -1
let screens = {}

const ResponsiveObserve = {
  matchHandlers: {} as {
    [prop: string]: {
      mql: MediaQueryList
      listener: ((this: MediaQueryList, ev: MediaQueryListEvent) => any) | null
    }
  },
  dispatch(pointMap: ScreenMap) {
    screens = pointMap
    subscribers.forEach(func => func(screens))
    return subscribers.size >= 1
  },
  subscribe(func: SubscribeFunc): number {
    if (!subscribers.size) this.register()
    subUid += 1
    subscribers.set(subUid, func)
    func(screens)
    return subUid
  },
  unsubscribe(token: number) {
    subscribers.delete(token)
    if (!subscribers.size) this.unregister()
  },
  unregister() {
    Object.keys(queries).forEach((screen: string) => {
      const matchMediaQuery = queries[screen]
      const handler = this.matchHandlers[matchMediaQuery]

      if (handler.listener) {
        handler.mql.removeEventListener('change', handler?.listener)
      }
    })
    subscribers.clear()
  },
  register() {
    Object.keys(queries).forEach((screen: string) => {
      const matchMediaQuery = queries[screen]
      const listener = ({ matches }: { matches: boolean }) => {
        this.dispatch({
          ...screens,
          [screen]: matches,
        })
      }
      const mql = win.matchMedia(matchMediaQuery)

      if (typeof mql?.addEventListener === 'function') {
        mql.addEventListener('change', listener)
      }

      if (typeof mql?.addEventListener === 'function') {
        mql.addEventListener('change', listener)
      }

      this.matchHandlers[matchMediaQuery] = {
        mql,
        listener,
      }

      listener(mql)
    })
  },
}

export const useScreens = (): [Set<Screen>, Array<Screen>] => {
  const [screens, setScreens] = useState<[Set<Screen>, Array<Screen>]>([
    new Set(),
    [],
  ])

  useEffect(() => {
    const token = ResponsiveObserve.subscribe(supportScreens => {
      const resolvedScreens = new Set<Screen>()

      for (const screen of SCREENS_HIERARCHY_REVERSE) {
        if (supportScreens[screen] === true) {
          resolvedScreens.add(screen)
        }
      }

      setScreens([resolvedScreens, Array.from(resolvedScreens)])
    })

    return () => ResponsiveObserve.unsubscribe(token)
  }, [])

  return screens
}
