import { useMemo } from 'react'

import { useCmsContext } from '../../contexts/cms'

export type Tag = cms.CMSData['tags'][string]

export const useTags = (): {
  table: Map<string, Tag>
  list: Array<Tag>
} => {
  const { cmsData } = useCmsContext()

  return useMemo(() => {
    const entities = Object.entries(cmsData.tags).map<[string, Tag]>(
      ([key, val]) => [val?.identifier ?? key, val as Tag]
    )

    return {
      table: new Map(entities),
      list: entities.map(([, entity]) => entity),
    }
  }, [cmsData])
}
