import { useMemo } from 'react'

import { useCmsContext } from '../../contexts/cms'

export type Software = cms.CMSData['software'][string]

export const useSoftware = (): {
  table: Map<string, Software>
  list: Array<Software>
} => {
  const { cmsData } = useCmsContext()

  return useMemo(() => {
    const entities = Object.entries(cmsData.software).map<[string, Software]>(
      ([key, val]) => [val?.identifier ?? key, val as Software]
    )

    return {
      table: new Map(entities),
      list: entities.map(([, entity]) => entity),
    }
  }, [cmsData])
}
