export enum Screen {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export const SCREENS: Record<Screen, number> = {
  [Screen.XSmall]: 47.94,
  [Screen.Small]: 54.97,
  [Screen.Medium]: 62,
  [Screen.Large]: 75,
  [Screen.XLarge]: 100,
}

export const SCREENS_HIERARCHY = Object.entries(SCREENS)
  .sort(([, a], [, b]) => a - b)
  .map(([screen]) => screen as Screen)

export const SCREENS_HIERARCHY_REVERSE: Screen[] =
  SCREENS_HIERARCHY.slice().reverse()

export const DEFAULT_SCREEN = SCREENS_HIERARCHY[0]
