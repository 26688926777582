import '../styles/components/overflow-indicator.scss'

import React, { useState, useRef, useLayoutEffect } from 'react'

interface OverflowIndicatorProps {
  children: React.ReactNode
}

export const OverflowIndicator: React.FC<OverflowIndicatorProps> = ({
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const checkOverflow = () => {
    if (containerRef.current) {
      const isOverflow =
        containerRef.current.scrollWidth > containerRef.current.clientWidth ||
        containerRef.current.scrollHeight > containerRef.current.clientHeight
      setIsOverflowing(isOverflow)
    }
  }

  useLayoutEffect(() => {
    checkOverflow()

    const handleResize = () => {
      checkOverflow()
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="overflow-container">
      <div ref={containerRef} className="overflow-container__child">
        {children}
      </div>
      {isOverflowing && <div className="overflow-indicator">...</div>}
    </div>
  )
}
