import '../styles/templates/productDetails.scss'

import { Link } from 'gatsby'
import React from 'react'

import { PAGE_NAME } from '../../config/cms'
import { getFilePath } from '../../src/utils/getFilePath'
import eclipseTick from '../../static/assets/svg/eclipseTick.svg'
import genericDeviceIcon from '../../static/assets/svg/generic-device-icon.svg'
import { Carousel } from '../components/carousel'
import { CaseStudiesList } from '../components/case-studies-list'
import { IconButton } from '../components/icon-button'
import { ArrowIndicator } from '../components/icons/arrow-indicator'
import { Download } from '../components/icons/download'
import { Image } from '../components/image'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { ProductTile } from '../components/product-tile'
import { Screen } from '../config/screens'
import { useEntity } from '../hooks/cms/use-entity'
import { usePageData } from '../hooks/cms/use-page'
import type { Product } from '../hooks/cms/use-products'
import { useProducts } from '../hooks/cms/use-products'
import { useSoftware } from '../hooks/cms/use-software'
import { useTags } from '../hooks/cms/use-tags'
import { useHrefMaker } from '../hooks/use-href-maker'
import { useScreen } from '../hooks/use-screen'
import { PageComponentFactory } from '../utils/pages'

const getFileNameWithExt = (fileName, file) =>
  `${fileName}.${file.split('/').pop().split('.').pop()}`

const ProductDetailsPage = PageComponentFactory(() => {
  const product: Product = useEntity()
  const products = useProducts()

  const {
    generalSection,
    benefitsSection,
    descriptionSection,
    specificationSection,
  } = usePageData(PAGE_NAME.ProductDetails)
  const { strings } = usePageData(PAGE_NAME.CaseStudies)
  const tags = useTags()
  const software = useSoftware()

  const makeHref = useHrefMaker()

  const uniqueSoftwareTags = new Set(
    software.list.flatMap(element => element.identifier)
  )

  const isLarge = useScreen(Screen.Large)

  return (
    <Layout
      page={PAGE_NAME.ProductDetails}
      pathname={`/products/${product.identifier}`}
    >
      <main className="product-page">
        <section className="product-page__landing-section">
          <div className="container">
            <div className="product-page__grid-container">
              <div className="product-page__content">
                <PageHeading>{product.name}</PageHeading>
                <PageSubheading>{product.description}</PageSubheading>
                <ArrowIndicator />
              </div>
              <section className="product-page__image-container section">
                <img src={genericDeviceIcon} className="product-page__image" />
              </section>

              <section className="product-page__tag-list">
                <div>
                  <span className="product-page__tag-list-compability">
                    {generalSection.compability}
                  </span>
                  {product?.tags?.map((tag, index) => {
                    const title = tags.table.get(tag)?.title
                    const isSoftware = uniqueSoftwareTags.has(tag)

                    return (
                      <Link
                        to={makeHref(
                          isSoftware ? `/software/${tag}` : `/products`
                        )}
                        state={
                          isSoftware
                            ? null
                            : {
                                tag,
                              }
                        }
                        className="tag-element"
                        key={index}
                      >
                        {title}
                      </Link>
                    )
                  })}
                </div>
              </section>
            </div>
          </div>
        </section>

        <section className="product-description-section section">
          <h3 className="product-description-section__description">
            {product.altDescriptionSummary}
          </h3>
          <h4 className="product-description-section__alt-description">
            {product.altDescription}
          </h4>

          {product?.files && product.files.length > 0 ? (
            <div className="product-description-section__download-container">
              <div className="product-description-section__more-info">
                {descriptionSection.moreInformation}
              </div>

              <div className="product-description-section__download-elements">
                {(product?.files ?? []).map(
                  ({ displayedLabel, fileName, file }, index) => (
                    <IconButton
                      key={index}
                      href={getFilePath(file)}
                      className="product-description-section__download-el-spec"
                      variant="secondary"
                      target="_blank"
                      download={getFileNameWithExt(fileName, file)}
                      rel="noreferrer"
                    >
                      <>
                        {displayedLabel}
                        <Download />
                      </>
                    </IconButton>
                  )
                )}
              </div>
            </div>
          ) : null}
        </section>
        <section className="product-benefits-section section">
          <div className="product-benefits-section__benefits-list">
            <h3>{benefitsSection.benefits}</h3>
            {product.benefits?.map((benefit, index) => (
              <span className="product-benefits-section__benefit" key={index}>
                <img
                  src={eclipseTick}
                  className="product-benefits-section__benefit-image"
                />
                <p className="product-benefits-section__benefit-text">
                  {benefit}
                </p>
              </span>
            ))}
          </div>

          <div className="product-benefits-section__slider">
            <Carousel
              slides={product.media?.items.map((x, key) => (
                <img
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                  key={key}
                  src={getFilePath(x.image)}
                />
              ))}
            />
          </div>
        </section>
        <section className="product-specification-section section">
          {product.features?.map(({ top, bottom, image }, index) => (
            <div
              className={`product-specification-section__feature ${
                (index + 1) % 2 ? '' : 'even'
              }`}
              key={index}
            >
              <div className="product-specification-section__feature-image-container">
                <Image path={image} />
              </div>

              <div className="product-specification-section__feature-caption-container">
                <h4 className="product-specification-section__feature-name">
                  {top}
                </h4>
                <p className="product-specification-section__feature-detail">
                  {bottom}
                </p>
              </div>
            </div>
          ))}
        </section>
        <section className="product-case-study-section section">
          {product.additionalEq?.length && (
            <div className="product-case-study-section__additional-eq">
              <h3 className="product-case-study-section__additional-eq-header">
                {specificationSection.additionalEquipment}
              </h3>
              <div>
                <Carousel
                  visibleSlides={isLarge ? 2 : 1}
                  slides={product.additionalEq
                    .filter(
                      identifier =>
                        identifier !== product.identifier &&
                        !!products.table.get(identifier)
                    )
                    .map(identifier => (
                      <ProductTile
                        key={identifier}
                        product={products.table.get(identifier)!}
                      />
                    ))}
                />
              </div>
            </div>
          )}
          <CaseStudiesList
            strings={strings}
            filtersEnabled={false}
            productId={product.identifier}
          />
        </section>
      </main>
    </Layout>
  )
})

export default ProductDetailsPage
